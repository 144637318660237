/*
 *
 * TransactionList types
 * make by phamthainb
 */
import { TPagination } from '@configs/types';
import { ActionType } from 'typesafe-actions';
import * as actions from './actions';

export enum WithdrawStatus {
  DRAFT = 'draft',
  NEW = 'new',
  SUCCESS = 'success',
  CONFIRMED = 'confirmed',
  REFUSE = 'refuse',
}
export interface StoreTransactionList {
  search: TSearch;
  pagination: TPagination;
  total?: number;
  data: any[];
  type: string;
}

export type ActionsTransactionList = ActionType<typeof actions>;

export enum TStatus {
  all = '',
  success = 'success',
  processing = 'fail',
}

export enum TType {
  plus = 'plus',
  withdraw = 'withdraw',
}

export interface TSearch {
  id: string | null;
  startDate: Date | null;
  endDate: Date | null;
  status: string | null;
}

export interface TGetData {
  pagination: TPagination;
  type: string;
  search: TSearch;
}

export interface TSetData {
  data: any;
  total?: number;
}
