/*
 *
 * Order reducers
 * make by phamthainb
 */
import { Reducer } from 'redux';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as types from './constants';
import { ActionsOrder, StoreOrder } from './types';

const initState: StoreOrder = {
  data: [],
  reloadData: true,
  pagination: {
    pageNumber: 1,
    pageSize: 10,
  },
  total: 0,
  search: {},
};

const reducersOrder: Reducer<StoreOrder, ActionsOrder> = (
  state = initState,
  actions: ActionsOrder,
) => {
  switch (actions.type) {
    case types.PAGINATION: {
      return {
        ...state,
        pagination: actions.payload,
      };
    }

    case types.GET: {
      return {
        ...state,
        data: actions.payload.data,
        total: actions.payload.total,
      };
    }

    case types.SEARCH: {
      return {
        ...state,
        search: actions.payload,
        pagination: { ...state.pagination, pageNumber: 1 },
      };
    }

    default:
      return { ...state };
  }
};

export default reducersOrder;
