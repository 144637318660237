/*
 *
 * StorePage constants
 *  make by phamthainb
 */

export const GET = 'StorePage/GET';
export const SET_SEARCH = 'StorePage/SET_SEARCH';
export const SET_LIST_PRODUCT = 'StorePage/SET_LIST_PRODUCT';
export const SET_LIST_PRODUCT_PAGE = 'StorePage/SET_LIST_PRODUCT_PAGE';
export const SET_SHOP_PRODUCT = 'StorePage/SET_SHOP_PRODUCT';
export const SET_SHOP_PAGE = 'StorePage/SET_SHOP_PAGE';

export const GET_BUYER = 'StorePage/GET_BUYER';

//export const SET_SOURCE_PRODUCT = 'StorePage/SET_SOURCE_PRODUCT';
//export const SET_SOURCE_PRODUCT_PAGE = 'StorePage/SET_SOURCE_PRODUCT_PAGE';
export const SET_PICKER = 'StorePage/SET_PICKER';
export const CART_REFESH = 'StorePage/CART_REFESH';

//
export const NAME_STORE_LOCAL = 'cart_data';
