import { TPagination } from '@configs/types';
/*
 *
 * Refund types
 * make by phamthainb
 */
import { ActionType } from 'typesafe-actions';
import * as actions from './actions';

export interface StoreRefund {
  pagination: TPagination;
  data: any[];
  total?: number;
  search: TSearch
}

export interface TSearch {
  code: string | null,
  codeBill: string | null,
  startDate: Date | null,
  endDate: Date | null,
  status: string | null
}
// new, success, fail
export enum TStatus {
  all = '',
  new = 'new',
  success = 'success',
  fail = 'fail',
  pending = 'pending',
}

export interface TData {
  transaction: string,
  reason: string,
  reason_other: string,
  files?: any, 
}


export type ActionsRefund = ActionType<typeof actions>;
