/*
 *
 * Withdrawal
 * make by phamthainb
 */

import React, { memo } from 'react';
import ErrorBound from '@components/ErrorBound';
import useInjectReducer from '@redux/useInjectReducer';
import reducersWithdrawal from './store/reducers';
import WrapWithdrawal from './style';
import Section from '@containers/CLayout/LayoutDashBroad/Section';
import Withdr from './Withdr';

interface Props {}

// eslint-disable-next-line
function Withdrawal({}: Props) {
  useInjectReducer('Withdrawal', reducersWithdrawal);
  return (
    <ErrorBound>
      <WrapWithdrawal>
        <Section title="Rút tiền">
          <Withdr />
        </Section>
      </WrapWithdrawal>
    </ErrorBound>
  );
}
export default memo(Withdrawal);
