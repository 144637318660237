/*
 *
 * App types
 *
 */
import { ActionType } from 'typesafe-actions';
import * as actions from './actions';

export type AppStore = {
  loading: boolean;
  lang: AppLanguage;
  user: { [key in string]: any };
  ui: { [key in string]: any };
  onRefresh: boolean;
  countO: {
    statusCode: number;
    count: number;
  };
  data: { [key in string]: any }[];
};

export type ActionsApp = ActionType<typeof actions>;

export enum AppLanguage {
  vi = 'vi',
  en = 'en',
}
