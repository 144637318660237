import styled from 'styled-components';
import theme from '@styles/theme';
import { text } from '@styles/mixins';

export const NewsFooter = styled.div`
  background-color: ${theme.color.gray_bg};
  .footer-img {
    width: 100%;
  }
  .footer-title {
    ${text.lg};
    color: ${theme.color.dark} !important;
    margin-top: 0 !important;
    margin-bottom: 12px !important;
  }
  .border {
    width: 100%;
    height: 0;
    border-top: 2px dashed #8d95b0;
  }
  .row {
    padding: 80px 0;
    padding-bottom: 0px;

    .links {
      display: flex;
      justify-content: space-between;
      .link {
        color: ${theme.color.dark};
        ${text.lg};
        text-decoration: none;
      }
    }

    .column {
      .investers {
        display: flex;
      }
      .logo {
        width: 239px;
        height: auto;
      }
      h3 {
        ${text.lg};
        color: ${theme.color.blue_science};
        font-weight: 500;
      }
      p,
      span {
        color: ${theme.color.text_primary};
      }
      p {
        font-size: 14px;
        line-height: 21px;
      }
      .right {
        /* text-align: center; */
        white-space: nowrap;
        margin-left: 30px;
        font-size: 16px;
        line-height: 24px;
        margin-top: 65px;
        margin-bottom: 0;
        span {
          color: #00de8e;
        }
      }
      .detail {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        span {
          color: ${theme.color.text_primary};
          display: inline-block;
          text-decoration: none;
          ${text.base};
        }
        a {
          ${text.base};
          text-decoration: none;
          color: ${theme.color.text_primary};
        }
        svg {
          min-width: 20px;
          height: 23px;
        }
      }
    }
    .column_1 {
      h3 {
        margin-top: 16px;
        margin-bottom: 12px;
      }
      p {
        margin-top: 0px;
        margin-bottom: 50px;
      }
    }
    .column_2,
    .column_3 {
      h3 {
        margin-top: 16px;
        margin-bottom: 12px;
      }
    }
    .column_2 {
      // padding-left: 90px;
      // margin-top: 32px;
    }
    .column_3 {
      padding-left: 160px;
      .detail:nth-child(0) {
        transform: translateX(5px);
      }
    }
  }
  .row_2 {
    padding: 32px 0 0;
  }
  .certification {
    a {
      max-width: 170px;
      margin: 0 auto;
      img {
        width: 100%;
      }
    }
  }
  @media (max-width: 1199px) {
    .row {
      .column_3 {
        padding-left: 100px;
      }
      .links {
        .link {
          ${text.base};
        }
      }
    }
  }
  @media (max-width: 991px) {
    .row {
      .column_3,
      .column_2 {
        padding-left: 0px;
        .right {
          margin-top: 40px;
        }
      }
      .links {
        .link {
          /* ${text.sm}; */
        }
      }
      .column {
        .logo {
          width: 150px;
        }
      }
      .column_1 {
        h3 {
          margin-top: 10px;
          ${text.base};
        }
      }
    }
  }
  @media (max-width: 767px) {
    .border {
      margin: 0 15px;
    }
    .row {
      padding: 0;
      margin: 40px 0 20px !important;
      .column {
        .investers {
          justify-content: space-between;
        }
        p,
        span {
          font-size: 14px;
        }
        p {
          margin-bottom: 20px;
        }
      }
      .column_1 {
        h3 {
          margin-top: 20px;
        }
      }
      .column_2,
      .column_3 {
        h3 {
          margin-top: 0;
          margin-bottom: 20px;
        }
      }
    }
    .document {
      margin-top: 40px;
    }
  }
  @media (max-width: 679px) {
    .row {
      .column {
        .right {
          margin-left: 0;
          text-align: center;
        }
      }
    }
  }
  @media (max-width: 579px) {
    .row {
      .links {
        flex-direction: column;
        gap: 10px;
      }
    }
  }
`;
