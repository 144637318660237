/*
 *
 * App constants
 *
 */

export const LANGUAGE = 'App/LANGUAGE';
export const LOADING = 'App/LOADING';
export const SET_USER = 'App/SET_USER';
export const ON_REFRESH = 'App/ON_REFRESH';
export const UI = 'App/UI';
export const GETCOUNT = 'App/GETCOUNT';
export const SETCOUNT = 'App/SETCOUNT';
export const GET = 'App/GET';
