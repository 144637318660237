import Button from '@components/Button';
import React from 'react';
import { Input, Text } from '@components/index';
import { Controller, useForm } from 'react-hook-form';
import { HandleError } from '@components/HandleResponse';
import { ErrorMessage } from '@hookform/error-message';
import VALIDATE from '@helpers/validate';
import styled from 'styled-components';
import theme from '@styles/theme';
import AsyncSelect from 'react-select/async';
import { requestInterToken, requestToken } from '@api/axios';
import API_URL from '@api/url';
import { clearParams } from '@helpers/format';
import _ from 'lodash';
import { Alert } from '@components/Alert';

const loadOptions = (inputValue: string, callback: any) => {
  requestToken({
    method: 'GET',
    url: API_URL.REFUND.GET_REASON,
    params: clearParams({
      order: 'ASC',
      page: 1,
      take: 10,
      keyword: inputValue,
    }),
  })
    .then(res => {
      callback([
        ...res.data.data.map((item: any) => ({
          ...item,
          label: item.content,
          value: item.id,
        })),
        { label: 'Khác', value: 'other' },
      ]);
    })
    .catch(HandleError);
};

export default function ModalRefund({
  data,
  setShowRefund,
  callback,
}: {
  data: any;
  setShowRefund: any;
  callback: any;
}) {
  const { handleSubmit, errors, control, register, watch } = useForm();

  const onSubmit = (formData: any) => {
    const body = new FormData();
    body.append('transactionId', data.transactionId);
    body.append('content', formData['content']);
    body.append('files', formData['files']);
    if (formData.reason.value === 'other') {
      body.append('reasonOther', formData['reasonOther']);
    } else {
      body.append('reasonId', formData.reason.value);
    }

    requestInterToken({ method: 'POST', url: API_URL.REFUND.POST, data: body })
      .then(res => {
        // HandleSuccess(res);
        Alert({
          name: 'Yêu cầu thành công. Tiền sẽ được hoàn về tài khoản thanh toán sau 2 ngày',
          icon: 'success',
        });
        callback && callback();
        setShowRefund(false);
      })
      .catch(HandleError);
  };

  return (
    <SModalRefund
      as="form"
      encType="multipart/form-data"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="modal_input">
        <label>ID giao dịch</label>
        <Input disabled={true} value={data?.transactionId || 'Mã giao dịch'} />
      </div>
      <div className="modal_input">
        <label>Lý do</label>

        <Controller
          control={control}
          name="reason"
          rules={{ required: VALIDATE.REQUIRE }}
          render={props => (
            <AsyncSelect
              {...props}
              cacheOptions
              loadOptions={_.debounce(loadOptions, 300)}
              defaultOptions
            />
          )}
        />
      </div>

      {watch('reason') && watch('reason').value === 'other' && (
        <>
          <div className="modal_input">
            <label></label>
            <Input
              name="reasonOther"
              ref={register({
                required: VALIDATE.REQUIRE,
                maxLength: {
                  value: 255,
                  message: 'Tối đa 255 ký tự.',
                },
                minLength: {
                  value: 6,
                  message: 'Tối thiểu 6 ký tự.',
                },
              })}
            />
          </div>
          <ErrorMessage
            errors={errors}
            name="reasonOther"
            render={Text.ErrorMessage}
          />
        </>
      )}
      <div className="modal_input">
        <label>Nội dung</label>
        <textarea
          cols={40}
          rows={4}
          name="content"
          ref={register({
            required: VALIDATE.REQUIRE,
            maxLength: {
              value: 255,
              message: 'Tối đa 255 ký tự.',
            },
            minLength: {
              value: 6,
              message: 'Tối thiểu 6 ký tự.',
            },
          })}
        />
      </div>
      <ErrorMessage errors={errors} name="content" render={Text.ErrorMessage} />
      <div className="modal_input">
        <label>File đính kèm</label>
        <Input
          type="file"
          className="choose"
          name="files"
          multiple
          ref={register({ required: VALIDATE.REQUIRE })}
        />
      </div>
      <ErrorMessage errors={errors} name="files" render={Text.ErrorMessage} />

      <div className="modal_buttons">
        <Button
          className="cancel"
          onClick={() => {
            setShowRefund(false);
          }}
        >
          Hủy bỏ
        </Button>
        <Button type="submit">Thêm mới</Button>
      </div>
    </SModalRefund>
  );
}

export const SModalRefund = styled.form`
  .modal_input,
  .err-message {
    display: grid;
    grid-template-columns: 2fr 6fr;
  }
  .err-message {
    p {
      margin-bottom: 0;
    }
  }
  .modal_input {
    margin-top: 24px;
    &:nth-child(1) {
      margin-top: 0;
    }
    label {
      color: ${theme.color.text_phu};
      display: inline-block;
      align-self: center;
    }
    .choose {
      justify-self: flex-start;
    }
    textarea {
      border: 1px solid #e3e8f0;
      outline: none;
      padding: 10px 16px;
      font-family: ${theme.fonts['K2D-Light']};
      font-size: 16px;
      color: ${theme.color.text_chinh};
    }
    .choose {
      padding: 0;
      &::-webkit-file-upload-button {
        visibility: hidden;
      }
      &::before {
        content: 'Choose';
        display: inline-block;
        background-color: ${theme.color.link};
        border-radius: 2px;
        color: white;
        line-height: 24px;
        cursor: pointer;
        padding: 7px 45px;
      }
    }
  }
  .modal_buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 44px;
    ${Button} {
      display: inline-block;
      margin-left: 16px;
      border-radius: 4px;
      width: 160px;
      height: 40px;
    }
    .cancel {
      background-color: #e4effa;
      color: ${theme.color.body};
    }
  }
`;
