/*
 *
 * Refund reducers
 * make by phamthainb
 */
import { Reducer } from 'redux';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as types from './constants';
import { ActionsRefund, StoreRefund } from './types';

const initState: StoreRefund = {
  data: [],
  pagination: {
    pageSize: 10,
    pageNumber: 1,
  },
  total: 0,
  search: {
    startDate: null,
    endDate: null,
    status: null,
    code: null,
    codeBill: null,
  },
};

const reducersRefund: Reducer<StoreRefund, ActionsRefund> = (
  state = initState,
  actions: ActionsRefund,
) => {
  switch (actions.type) {
    case types.GET: {
      return {
        ...state,
        data: actions.payload.data,
        total: actions.payload.total,
      };
    }

    case types.PAGINATION: {
      return {
        ...state,
        pagination: actions.payload,
      };
    }

    case types.SEARCH: {
      // const {
      //   code,
      //   codeBill,
      //   endDate,
      //   status,
      //   startDate,
      // } = actions.payload;
      // const { search } = state;
      // if (
      //   code !== search.code ||
      //   codeBill !== search.codeBill ||
      //   endDate?.getTime() !== search.endDate?.getTime() ||
      //   status !== search.status ||
      //   startDate?.getTime() !== search.startDate?.getTime()
      // ) {
      //   return {...state, search: {...actions.payload}}
      // }
      // return {...state}
      return {
        ...state,
        search: { ...actions.payload },
        pagination: { ...state.pagination, pageNumber: 1 },
      };
    }

    default:
      return { ...state };
  }
};

export default reducersRefund;
