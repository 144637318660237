import React, { ReactChild, ReactChildren } from 'react';
import styled from 'styled-components';
import Header from './Header';

interface Props {
  children: ReactChild | ReactChildren;
}

const LayoutStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;

  .notify {
    background: #3acb83;
    padding: 0 25px;
    color: white;
    z-index: 99;
    position: fixed;
    width: 100%;

    img.icon-notify {
      position: absolute;
      right: 5px;
      top: 8px;
      cursor: pointer;
    }
  }
`;

const LayoutNews = ({ children }: Props) => {
  return (
    <LayoutStyle>
      <Header />
      <div>{children}</div>
    </LayoutStyle>
  );
};

export default LayoutNews;
