import store from '@redux/store';
import axios from 'axios';
import FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { cleanObject, getValueObject } from '.';
import { changeLoading } from '@containers/App/store/actions';
import { requestToken } from '@api/axios';

const fileType =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

const fileExtension = '.xlsx';

const exportExcel = (csvData: object[], fileName: string) => {
  const ws = XLSX.utils.json_to_sheet(csvData);
  const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};

export const getDataExport = async ({
  total,
  url,
  search,
  pageSize = 1000,
}: any) => {

  store.dispatch(changeLoading(true));

  let array = [],
    result: any[] = [];
  for (let index = 1; index <= Math.ceil(total / pageSize); index++) {
    const req = await requestToken({
      method: 'GET',
      url: url,
      params: cleanObject({ ...search, pageSize: pageSize, page: index }),
    });
    array.push(req);
  }

  await axios
    .all(array)
    .then(res => {
      res.forEach(item => {
        result = result.concat(item.data.data);
      });
    })
    .catch(err => {
      //console.log(err);
    });
  store.dispatch(changeLoading(false));
  return result;
};

export const mapDataExport = (
  head: { name: string; key: string; callback?: (val: any) => any }[], // callback: format data
  data: any[],
) => {
  return data.map((item, i) => {
    let row: any = {};
    head.forEach((headItem, j) => {
      let valItem = getValueObject(headItem.key, item);
      if(headItem.key === "") valItem = item;
      if (headItem.callback) {
        row[headItem.name] = headItem.callback(valItem);
      } else row[headItem.name] = valItem;
    });
    return row;
  });
};

export const exportToCSV = (csvData: any, fileName: string) => {
  const ws = XLSX.utils.json_to_sheet(csvData);
  const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};

export default exportExcel;
