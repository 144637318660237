import Button from '@components/Button';
import Input from '@components/Input';
import { ErrorMessage } from '@hookform/error-message';
import VALIDATE from '@helpers/validate';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Text } from '@components/index';
import { requestInterToken } from '@api/axios';
import { Alert } from '@components/Alert';
import API_URL from '@api/url';
import { StyleModal } from './style';
import { useSelector } from 'react-redux';
import { selectWithdrawalStore } from './store/selecters';

import { TypeOtp } from '@helpers/index';
import useCountDown from '@hooks/useCountDown';
import CountDownTimer from '@components/CountDownTimer';

export default function ModalOTP({
  toggleModalNotify,
  toggleModal,
}: {
  toggleModal: any;
  toggleModalNotify: any;
}) {
  const { transactionId } = useSelector(selectWithdrawalStore);
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = (data: any) => {
    requestInterToken({
      method: 'PUT',
      url: API_URL.WITHDRAW.PUTCHECKOTP,
      data: {
        otp: data.codeOTP,
        type: TypeOtp.WITHDRAW,
        withdrawId: transactionId,
      },
    })
      .then(res => {
        if (res.status === 200) {
          toggleModalNotify();
        }
        Alert({ name: 'Đã tạo rút tiền', icon: 'success' });
      })
      .catch(err => Alert({ name: err.response.data.message, icon: 'error' }));
  };

  const onSendOTP = (reOtp: boolean) => {
    requestInterToken({
      method: 'POST',
      url: API_URL.WITHDRAW.POSTOTP,
      data: {
        reOtp: reOtp,
        type: TypeOtp.WITHDRAW,
        withdrawId: transactionId,
      },
    })
      .then(res => {
        setCount(count + 1);
        Alert({ name: 'Đã yêu cầu gữi mã OTP', icon: 'success' });
      })
      .catch(err => Alert({ name: err.response.data.message, icon: 'error' }));
  };

  const { timeLeft, setCount, count } = useCountDown(120);

  return (
    <StyleModal>
      <h3>
        Một đoạn mã được gửi về số điện thoại của bạn vui lòng kiểm tra điện
        thoại và nhập mã OTP đã được gửi.
      </h3>
      <form onSubmit={handleSubmit(onSubmit)} className="form-data">
        <div className="sendOTP">
          <label className="field-input">
            <p className="text">Nhập mã OTP</p>
            <div className="infor_withdr">
              <Input
                ref={register({ required: VALIDATE.REQUIRE })}
                name="codeOTP"
                placeholder="Nhập mã"
                disabled={!count}
              />
              <ErrorMessage
                errors={errors}
                name="codeOTP"
                render={Text.ErrorMessage}
              />
            </div>
          </label>

          {count > 0 ? (
            <CountDownTimer time={timeLeft} />
          ) : (
            <Button
              className="btt"
              type="button"
              disabled={Boolean(count % 2)}
              style={{ background: 'white' }}
              onClick={() => onSendOTP(false)}
            >
              Gửi mã OTP
            </Button>
          )}
        </div>
        {count >= 2 && (
          <p className="resend">
            Không nhận được mã OTP? Ấn
            <button
              type="button"
              disabled={Boolean(count % 2)}
              onClick={() => onSendOTP(true)}
            >
              ĐÂY
            </button>{' '}
            để gửi lại.
          </p>
        )}
        <div className="button">
          {/* <Button
            className="cancel bt"
            type="button"
            onClick={() => {
              toggleModal(false);
            }}
          >
            Hủy bỏ
          </Button> */}
          <Button className="bt" type="submit">
            Xác nhận
          </Button>
        </div>
      </form>
    </StyleModal>
  );
}
