/** @format */

import { useEffect } from 'react';
/**
 * useDetectClickOutSide : detect when user click outside component point
 * @param {*} ref the ref of component, tag want set
 * @param {*} handler Function hande run if click outside ref
 * @param {*} refParent the ref of component, tag is control of (ref)
 * @param {*} handlerParent Function hande run if click ```refParent```
 */

type Props = {
  ref: React.MutableRefObject<any>;
  handler: Function;
  refParent?: React.MutableRefObject<any>;
  handlerParent?: Function;
};

function useDetectClickOutSide({
  handler,
  handlerParent,
  ref,
  refParent,
}: Props) {
  useEffect(() => {
    const listener = (event: Event) => {
      // Do handlerParent if clicking ref's of Parent
      // console.log(event.target, refParent?.current);

      if (refParent?.current?.contains(event.target)) {
        handlerParent && handlerParent();
        return;
      }
      // Do nothing if clicking ref's element or descendent elements
      if (!ref?.current || ref.current.contains(event.target)) return;
      // run handler if click outside
      handler && handler();
    };

    document.addEventListener('mousedown', listener);
    // document.addEventListener('touchstart', listener);

    return () => {
      // console.log('un addEventListener');

      document.removeEventListener('mousedown', listener);
      // document.removeEventListener('touchstart', listener);
    };
  }, [ref, refParent, handler, handlerParent]);
}

export default useDetectClickOutSide;
