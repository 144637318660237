/*
 *
 * Order actions
 * make by phamthainb
 */
import * as types from './constants';
import { action } from 'typesafe-actions';
import { TGetData } from '@containers/PaymentLinks/store/actions';
import { requestToken } from '@api/axios';
import API_URL from '@api/url';
import { clearParams } from '@helpers/format';
import { TPagination } from '@configs/types';

export const setDataOrder = ({ data, total }: { data: any; total: number }) => {
  return action(types.GET, { data: data, total: total });
};

export const changePage = (pagination: TPagination) =>
  action(types.PAGINATION, pagination);

export const getDataOrder = ({ search, pagination }: TGetData) =>
  requestToken({
    method: 'GET',
    url: API_URL.ORDER.GET,
    params: clearParams({
      take: pagination.pageSize,
      page: pagination.pageNumber,
      ...search,
    }),
  });

  export const changeSearch = (
    search: {
      [key in string]: any;
    },
  ) => action(types.SEARCH, search);