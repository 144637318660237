import React, { useEffect, useRef } from 'react';
import QRCodeStyling from 'qr-code-styling';
import ILogo from '@assets/images/logo-icon.svg';
import Button from '@components/Button';
import ICopyLink from '@assets/images/copy_link.svg';

const qrCode = new QRCodeStyling({
  width: 300,
  height: 300,
  image: ILogo,
  dotsOptions: {
    // color: '#03cb83',
    type: 'rounded',
  },
  imageOptions: {
    crossOrigin: 'anonymous',
    margin: 20,
  },
});

export default function QRCode({ linkUrl }: { linkUrl: string }) {
  const ref = useRef<any>(null);

  useEffect(() => {
    qrCode.append(ref.current);
  }, []);

  useEffect(() => {
    qrCode.update({
      data: linkUrl,
    });
  }, [linkUrl]);

  const onDownloadClick = () => {
    qrCode.download({
      extension: 'png',
      name: 'BytePay-QRCode',
    });
  };

  return (
    <div className="content-tab">
      <div className="qrcode">
        <span ref={ref} />
      </div>
      <div className="buttons">
        <div className="button_copy">
          <Button onClick={onDownloadClick}>
            <img src={ICopyLink} alt="icon" />
            Tải xuống
          </Button>
        </div>
      </div>
    </div>
  );
}
