/*
*
* AccountVerify reducers
* make by phamthainb
*/
import { Reducer } from 'redux';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as types from './constants';
import { ActionsAccountVerify, StoreAccountVerify } from "./types";

const initState : StoreAccountVerify = { };

const reducersAccountVerify : Reducer<StoreAccountVerify, ActionsAccountVerify> = (state = initState, actions: ActionsAccountVerify) => {
switch (actions.type) {
default:
return { ...state };
}
};

export default reducersAccountVerify;