/*
 *
 * App reducers
 *
 */
import { Reducer } from 'redux';
import * as types from './constants';
import { ActionsApp, AppStore } from './types';
import { AppLanguage } from './types';

const initState: AppStore = {
  lang: AppLanguage['vi'],
  loading: false,
  user: {},
  ui: { sidebar: false, overlay: false },
  onRefresh: false,
  countO: {
    statusCode: 0,
    count: 0,
  },
  data: [],
};

const reducersApp: Reducer<AppStore, ActionsApp> = (
  state = initState,
  actions: ActionsApp,
) => {
  switch (actions.type) {
    case types.LOADING: {
      return { ...state, loading: actions.payload };
    }

    case types.LANGUAGE: {
      return { ...state, lang: actions.payload };
    }

    case types.SET_USER: {
      return { ...state, user: { ...actions.payload } };
    }

    case types.ON_REFRESH: {
      return { ...state, onRefresh: !state.onRefresh };
    }

    case types.UI: {
      return { ...state, ui: actions.payload };
    }

    case types.SETCOUNT:
      return {
        ...state,
        countO: actions.payload,
      };

    case types.GET: {
      return {
        ...state,
        data: [...state.data, ...actions.payload.data],
        total: actions.payload.total,
        // total: actions.payload.meta.total,
      };
    }

    default:
      return { ...state };
  }
};

export default reducersApp;
