import { Alert } from '@components/Alert';
import { AxiosResponse } from 'axios';

export const HandleSuccess = (res: Response | AxiosResponse) => {
  // console.log("res", res);
  Alert({
    icon: 'success',
    name: 'Thành công.',
  });
};

export const HandleError = (err: any) => {
  console.log(JSON.stringify(err));
  Alert({
    name: err.response ? err.response.data.message : 'Đã xảy ra lỗi.',
    icon: 'error',
  });
};
