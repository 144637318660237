/*
 *
 * Dashboard actions
 * make by phamthainb
 */
import * as types from './constants';
import { action } from 'typesafe-actions';
import { requestToken } from '@api/axios';
import API_URL from '@api/url';
import { HandleError } from '@components/HandleResponse';

export const changeLogin = (val: boolean) => action(types.GET, val);

export const setStatistic = (data: any) => action(types.GET_STATISTIC, data);

export const disStatistic = () => (dispatch: any) => {
  requestToken({ method: 'GET', url: API_URL.DASHBOARD.STATISTIC })
    .then(res => dispatch(setStatistic(res.data)))
    .catch(HandleError);
};
