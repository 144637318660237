/*
 *
 * Dashboard reducers
 * make by phamthainb
 */
import { Reducer } from 'redux';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as types from './constants';
import { ActionsDashboard, StoreDashboard } from './types';

const initState: StoreDashboard = {
  statistic: {
    totalTransaction: 0,
    totalPaymentLinkCreated: 0,
    totalPaymentLinkPaid: 0,
    totalWithdraw: 0,
  },
};

const reducersDashboard: Reducer<StoreDashboard, ActionsDashboard> = (
  state = initState,
  actions: ActionsDashboard,
) => {
  switch (actions.type) {
    case types.GET_STATISTIC: {
      return { ...state, statistic: actions.payload };
    }
    default:
      return { ...state };
  }
};

export default reducersDashboard;
