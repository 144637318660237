import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as types from './store/types';
import { DatePicker, Input, Button } from '@components/index';
import HookForm from '@components/Input/HookForm';
import { handleSearch } from './store/actions';
import { useDispatch } from 'react-redux';
import {
  ButtonWrapper,
  SearchItem,
  SearchLayout,
  SearchWrapper,
} from '@components/SearchLayout';

const list = [
  { name: 'Mã giao dịch', key: 'code' },
  { name: 'Mã đơn hàng', key: 'codeBill' },
  { name: 'Trạng thái', key: 'status' },
  { name: 'Bắt đầu', key: 'startDate' },
  { name: 'Kết thúc', key: 'endDate' },
];
// new, success, fail
const status = [
  { label: '--', value: String(types.TStatus.all) },
  { label: 'Mới', value: String(types.TStatus.new) },
  { label: 'Đang chờ hoàn tiền', value: String(types.TStatus.pending) },
  { label: 'Thành công', value: String(types.TStatus.success) },
  { label: 'Thất bại', value: String(types.TStatus.fail) },
];

export default function Search() {
  const { register, handleSubmit, control } = useForm();
  const dispatch = useDispatch();

  const onSubmit = (data: any) => {
    const { code, codeBill, endDate, startDate, status } = data;
    dispatch(
      handleSearch({
        code: code === '' ? null : code,
        codeBill: codeBill === '' ? null : codeBill,
        endDate: endDate === undefined ? null : endDate,
        startDate: startDate === undefined ? null : startDate,
        status: status.value === '' ? null : status.value,
      }),
    );
  };

  return (
    <SearchLayout onSubmit={handleSubmit(onSubmit)}>
      <SearchWrapper>
        {list.map(input => {
          if (input.key === 'status') {
            return (
              <SearchItem>
                <p className="text">{input.name}</p>
                <HookForm.InputSelect
                  controller={{
                    control,
                    render: () => <p>status</p>,
                    name: 'status',
                    defaultValue: { ...status[0] },
                  }}
                  select={{
                    options: status,
                  }}
                />
              </SearchItem>
            );
          } else if (input.key === 'startDate' || input.key === 'endDate') {
            return (
              <SearchItem>
                <p className="text">{input.name}</p>
                <Controller
                  control={control}
                  name={input.key}
                  render={({ onChange, value }) => (
                    <DatePicker onDayChange={onChange} />
                  )}
                />
              </SearchItem>
            );
          } else {
            return (
              <SearchItem key={input.key}>
                <p className="text">{input.name}</p>
                <Input name={input.key} ref={register} />
              </SearchItem>
            );
          }
        })}
      </SearchWrapper>
      <ButtonWrapper>
        <Button type="submit">Tìm kiếm</Button>
      </ButtonWrapper>
    </SearchLayout>
  );
}
