/**
 * CS = Config for styles of Layout component
 */
const CSLayout = {
  sidebar: {
    width: 176,
  },
};

export default CSLayout;
