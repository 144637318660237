/*
 *
 * Withdrawal constants
 *  make by phamthainb
 */

export const GET = 'Withdrawal/GET';
export const SETDATA = 'Withdrawal/SETDATA';
export const SETBANK = 'Withdrawal/SETBANK';
export const SETTRANSACTIONTD = 'Withdrawal/SETTRANSACTIONTD';


