/*
 *
 * TransactionList selecters
 * make by phamthainb
 */
import rootReducer from 'src/redux/root-reducers';

export const selectTransactionListStore = (
  state: ReturnType<typeof rootReducer>,
) => state.TransactionList;
  