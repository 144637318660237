/*
 *
 * PaymentLinks constants
 *  make by phamthainb
 */

export const GET = 'PaymentLinks/GET';
export const PAGINATION = 'PaymentLinks/PAGINATION';
export const PAGINATION_SIZE = 'PaymentLinks/PAGINATION_SIZE';
export const SEARCH = 'PaymentLinks/SEARCH';
export const RELOAD_DATA = 'PaymentLinks/RELOAD_DATA';
