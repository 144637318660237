/*
 *
 * Products reducers
 * make by phamthainb
 */
import { Reducer } from 'redux';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as types from './constants';
import { ActionsProducts, StoreProducts } from './types';

const initState: StoreProducts = {
  data: [],
  pagination: {
    pageSize: 10,
    pageNumber: 1,
  },
  total: 0,
  onRefresh: false,
  search: {
    keyword: null,
    status: null,
  },
};

const reducersProducts: Reducer<StoreProducts, ActionsProducts> = (
  state = initState,
  actions: ActionsProducts,
) => {
  switch (actions.type) {
    case types.GET: {
      return {
        ...state,
        data: actions.payload.data,
        total: actions.payload.total,
      };
    }

    case types.PAGINATION: {
      return {
        ...state,
        pagination: actions.payload,
      };
    }

    case types.ON_REFRESH: {
      return {
        ...state,
        onRefresh: !state.onRefresh,
      };
    }

    case types.SEARCH: {
      return {
        ...state,
        search: { ...actions.payload },
        pagination: { ...state.pagination, pageNumber: 1 },
      };
    }

    default:
      return { ...state };
  }
};

export default reducersProducts;
