/*
 *
 * Withdrawal reducers
 * make by phamthainb
 */
import { Reducer } from 'redux';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as types from './constants';
import { ActionsWithdrawal, StoreWithdrawal } from './types';

const initState: StoreWithdrawal = {
  money: 0,
  banks: {
    list: [],
    total: 0,
  },
  transactionId: ""
};

const reducersWithdrawal: Reducer<StoreWithdrawal, ActionsWithdrawal> = (
  state = initState,
  actions: ActionsWithdrawal,
) => {
  switch (actions.type) {
    case types.SETDATA:
      return { ...state, dataForm: { ...actions.payload } };

    case types.SETBANK: {
      return {
        ...state,
        banks: {
          list: actions.payload.data,
          total: actions.payload.total,
        },
      }
    }

    case types.SETTRANSACTIONTD: {
      return {
        ...state,
        transactionId: actions.payload
      }
    }
    default:
      return { ...state };
  }
};

export default reducersWithdrawal;
