/*
 *
 * App actions
 *
 */
import * as types from './constants';
import { AppLanguage } from './types';
import { action } from 'typesafe-actions';
import { HandleError } from '@components/HandleResponse';
import { requestToken } from '@api/axios';
import API_URL from '@api/url';

export const changeLanguage = (val: AppLanguage) => action(types.LANGUAGE, val);

export const changeLoading = (val: boolean) => action(types.LOADING, val);

export const setUser = (user: { [key in string]: any }) =>
  action(types.SET_USER, { ...user });

export const onRefresh = () => action(types.ON_REFRESH);

export const updateUI = (ui: { [key in string]: any }) => action(types.UI, ui);

export const setCount = (countO: { statusCode: number; count: number }) =>
  action(types.SETCOUNT, countO);

export const setNotifi = (data: any) => action(types.GET, data);

export const getNotifiApp = () => (
  dispatch: any,
) => {
  requestToken({
    url: API_URL.NOTIFICATION.GETNOTIFICATION,
    method: 'GET',
  })
    .then(res =>
      dispatch(setNotifi({ data: res.data.data, total: res.data.meta.total })),
    )
    .catch(HandleError);
};
