import { ReactComponent as EmailIcon } from '@assets/images/Home/email-icon.svg';
import { ReactComponent as Logo } from '@assets/images/logo.svg';
import React from 'react';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import { NewsFooter } from '../../../components/Layout/NewsLayout/Footer';
// import { ReactComponent as FacebookIcon } from '@assets/images/Home/facebook-icon.svg';
// import { ReactComponent as YoutubeIcon } from '@assets/images/Home/yt-icon.svg';
// import { ReactComponent as IgIcon } from '@assets/images/Home/instagram-icon.svg';
import footerImg from '@assets/images/mobeos/home/footer_img1.png';
import Image from '@components/Image';
// import logoCCDV from 'public/images/logo-ccdv.png';

const docs = [
  {
    name: 'Quy chế hoạt động',
    url: '/docs/quyche.pdf',
    icon: <EmailIcon />,
  },
  {
    name: 'Chính sách bảo vệ thông tin cá nhân',
    url: '/docs/chinhsach_bvttvn.pdf',
    icon: <EmailIcon />,
  },
  {
    name: 'Cơ chế giải quyết tranh chấp',
    url: '/docs/chinhsach_gqtt.pdf',
    icon: <EmailIcon />,
  },
  {
    name: 'Quy định quản lý, kiểm soát thông tin',
    url: '/docs/chinhsach_qltt.pdf',
    icon: <EmailIcon />,
  },
];

const docs1 = [
  {
    name: 'Về chúng tôi',
    url: '/docs/about-us.pdf',
    icon: <EmailIcon />,
  },
  {
    name: 'Liên hệ',
    url: '/docs/contact-us.pdf',
    icon: <EmailIcon />,
  },
  {
    name: 'Quy trình đổi trả và hoàn tiền',
    url: '/docs/refund-return-policy.pdf',
    icon: <EmailIcon />,
  },
  {
    name: 'Quy trình vận chuyển',
    url: '/docs/shipping-policy.pdf',
    icon: <EmailIcon />,
  },
];

export default function Footer() {
  return (
    <NewsFooter>
      <Container>
        <Row>
          {/* <div className="border"></div> */}
          <Image src={footerImg} className="footer-img" />
        </Row>
      </Container>

      <Container>
        <Row className="row" alignItems="center">
          <Col col md={6} sm={12} xs={12}>
            <div
              className="column column_1"
              // //data-aos="fade-up"
              // //data-aos-duration="1000"
            >
              <Logo className="logo" />
              <h3>Nền tảng Thương Mại Điện Tử Toàn Diện</h3>
            </div>
          </Col>
        </Row>

        <Row className="row row_2">
          <Col col md={4} sm={6} xs={12}>
            <div className="column column_2">
              <h3 className="footer-title">Liên hệ</h3>
              <div className="detail">
                <span>
                  Địa chỉ: Số 6, Khu tập thể cầu 7, đường Lê Đức Thọ, Phường Mỹ
                  Đình 2, Quận Nam Từ Liêm, Thành phố Hà Nội, Việt Nam
                </span>
              </div>
              <div className="detail">
                <span>Hotline: 039 7950 030</span>
              </div>
              <div className="detail">
                <a href="mailto:egaopts.jsc@gmail.com">
                  Email:egaopts.jsc@gmail.com
                </a>
              </div>
            </div>
          </Col>

          <Col col md={8} sm={6} xs={12} className="document">
            <h3 className="footer-title">Tài liệu</h3>
            <Row>
              <Col col md={6} sm={12} xs={12}>
                <div className="column column_2">
                  {docs.map((k, i) => (
                    <div
                      className="detail"
                      key={i}
                      style={{ cursor: 'pointer' }}
                    >
                      <span onClick={() => window.open(k?.url)}>{k?.name}</span>
                    </div>
                  ))}
                </div>
              </Col>
              <Col col md={6} sm={12} xs={12}>
                <div className="column column_2">
                  {docs1.map((k, i) => (
                    <div
                      className="detail"
                      key={i}
                      style={{ cursor: 'pointer' }}
                    >
                      <span onClick={() => window.open(k?.url)}>{k?.name}</span>
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="certification">
          <a href="http://online.gov.vn/Home/WebDetails/121762" target="_blank">
            <img alt="certification" src="/images/logo-ccdv.png" />
          </a>
        </Row>

        <Row>
          <p
            style={{
              width: '100%',
              textAlign: 'center',
              color: '#8d95b0',
              lineHeight: '28px',
            }}
          >
            Công ty Cổ phần Sản xuất, Thương mại và Dịch vụ Egao | MST:
            0108639747 | Ngày cấp: 08/03/2019 <br />
            Nơi cấp: P. ĐKKD - Sở KH&ĐT TP. Hà Nội | Người đại diện pháp luật:
            Bùi Ngọc Hiến
          </p>
        </Row>
      </Container>
    </NewsFooter>
  );
}
