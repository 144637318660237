import Button from '@components/Button';
import React from 'react';
import { Text } from '@components/index';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import VALIDATE from '@helpers/validate';
import styled from 'styled-components';
import { requestInterToken } from '@api/axios';
import API_URL from '@api/url';
import { HandleError, HandleSuccess } from '@components/HandleResponse';
import { SModalRefund } from './ModalRefund';

export default function ModalSupport({
  data,
  setOpen,
  callback,
}: {
  data: any;
  setOpen: any;
  callback: any;
}) {
  const { handleSubmit, errors, register } = useForm();

  const onSubmit = (formData: any) => {
    requestInterToken({
      method: 'POST',
      url: API_URL.PAYMENTLINK.SUPPORT,
      data: {
        content: formData.content,
        paymentLinkId: data.id,
      },
    })
      .then(res => {
        setOpen(false);
        callback && callback();
        HandleSuccess(res);
      })
      .catch(HandleError);
  };

  return (
    <SModalSupport
      as="form"
      encType="multipart/form-data"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="modal_input">
        <label>Nội dung</label>
        <textarea
          cols={40}
          rows={4}
          name="content"
          ref={register({
            required: VALIDATE.REQUIRE,
            maxLength: {
              value: 255,
              message: 'Tối đa 255 ký tự.',
            },
            minLength: {
              value: 6,
              message: 'Tối thiểu 6 ký tự.',
            },
          })}
        />
        <ErrorMessage
          errors={errors}
          name="content"
          render={Text.ErrorMessage}
        />
      </div>

      <div className="modal_buttons">
        <Button
          className="cancel"
          onClick={() => {
            setOpen(false);
          }}
        >
          Hủy bỏ
        </Button>
        <Button type="submit">Gửi yêu cầu</Button>
      </div>
    </SModalSupport>
  );
}

const SModalSupport = styled(SModalRefund)``;
