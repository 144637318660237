/*
 *
 * TransactionList reducers
 * make by phamthainb
 */
import { Reducer } from 'redux';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ActionsTransactionList, StoreTransactionList } from './types';
import * as types from './constants';

const initState: StoreTransactionList = {
  search: {
    id: null,
    startDate: null,
    endDate: null,
    status: null,
  },
  pagination: {
    pageSize: 10,
    pageNumber: 1,
  },
  total: 0,
  data: [],
  type: 'withdraw',
};

const reducersTransactionList: Reducer<
  StoreTransactionList,
  ActionsTransactionList
> = (state = initState, actions: ActionsTransactionList) => {
  switch (actions.type) {
    case types.PAGINATION:
      return { ...state, pagination: { ...actions.payload } };

    case types.GET:
      return {
        ...state,
        data: actions.payload.data,
        total: actions.payload.total,
      };

    case types.CHANGE_TYPE:
      return { ...state, type: actions.payload };

    case types.SEARCH:
      return {...state, search: {...actions.payload}}

    default:
      return { ...state };
  }
};

export default reducersTransactionList;
