import React, { ImgHTMLAttributes, useState } from 'react';
import styled from 'styled-components';
import notFound from '@assets/images/notfound-image.jpg';

export default function Image(props: ImgHTMLAttributes<any>) {
  const [status, setStus] = useState<'none' | 'loaded' | 'error'>('none');
  const onError = (e: any) => {
    e.target.onerror = null;
    e.target.src = notFound;
  };

  return (
    <SImage
      onLoad={() => {
        setStus('loaded');
      }}
      className={`${status === 'none' ? 'skeleton-img' : ''}`}
      loading="eager"
      onError={onError}
      {...props}
    />
  );
}

const SImage = styled.img`
  animation: lazy 0.5s ease-in forwards;

  &.skeleton-img {
    display: inline-block;
    height: 1em;
    position: relative;
    overflow: hidden;
    background-color: #dddbdd;

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background-image: linear-gradient(
        90deg,
        rgba(#fff, 0) 0,
        rgba(#fff, 0.2) 20%,
        rgba(#fff, 0.5) 60%,
        rgba(#fff, 0)
      );
      animation: shimmer 2s infinite;
      content: '';
    }
  }

  @keyframes lazy {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
`;
