/*
 *
 * PaymentLinks actions
 * make by phamthainb
 */
import * as types from './constants';
import { action } from 'typesafe-actions';
import { TPagination } from '@configs/types';
import { requestToken } from '@api/axios';
import API_URL from '@api/url';
import { clearParams } from '@helpers/format';

export type TGetData = {
  pagination: TPagination;
  search: {
    [key in string]: any;
  };
};

export const setData = ({ data, total }: { data: any; total: number }) => {
  return action(types.GET, { data: data, total: total });
};

export const changePage = (pagination: TPagination) =>
  action(types.PAGINATION, pagination);

export const reloadData = () => action(types.RELOAD_DATA);

export const changeSearch = (
  search: {
    [key in string]: any;
  },
) => action(types.SEARCH, search);

export const getData = ({ search, pagination }: TGetData) =>
  requestToken({
    method: 'GET',
    url: API_URL.PAYMENTLINK.GET,
    params: clearParams({
      take: pagination.pageSize,
      page: pagination.pageNumber,
      ...search,
    }),
  });
