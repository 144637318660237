import Input, { Select } from '@components/Input';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Button from '@components/Button';
import { useDispatch } from 'react-redux';
import { handleSearch } from './store/actions';
import {
  ButtonWrapper,
  SearchItem,
  SearchLayout,
  SearchWrapper,
} from '@components/SearchLayout';
import { request } from '@api/axios';
import API_URL from '@api/url';

const status = [
  { label: '--', value: '' },
  { label: 'Hoạt động', value: 'active' },
  { label: 'Không hoạt động', value: 'lock' },
  { label: 'Chưa duyệt', value: 'approve-lock' },
  { label: 'Đã duyệt', value: 'approve-active' },
  { label: 'Từ chối', value: 'approve-reject' },
];

export default function Search() {
  const { handleSubmit, register, control } = useForm();
  const dispatch = useDispatch();

  const onSubmit = (data: any) => {
    // console.log('data::', data);
    let s = {};
    switch (data.status.value) {
      case 'active': {
        s = { status: 'active', categoryStatus: true, adminApprove: 'active' };
        break;
      }
      case 'lock': {
        s = { isDisable: true };
        break;
      }
      case 'approve-lock': {
        s = { adminApprove: 'lock' };
        break;
      }
      case 'approve-active': {
        s = { adminApprove: 'active' };
        break;
      }
      case 'approve-reject': {
        s = { adminApprove: 'reject' };
        break;
      }
    }

    dispatch(
      handleSearch({
        keyword: data.name || null,
        categoryId: data.category.value || null,
        ...s,
      }),
    );
  };

  const [category, setCategory] = useState([]);

  useEffect(() => {
    request({
      method: 'GET',
      url: API_URL.PRODUCTS.GET_CATEGORY,
      params: { all: true },
    }).then(res => {
      setCategory(res?.data?.data || []);
    });
  }, []);

  const categoryOptions = useMemo(
    () => [
      { label: 'Tất cả', value: null },
      ...category.map((k: any, i) => ({
        label: k.name,
        value: k.id,
      })),
    ],
    [category],
  );

  return (
    <SearchLayout onSubmit={handleSubmit(onSubmit)}>
      <SearchWrapper>
        <SearchItem>
          <p className="text">Tên sản phẩm</p>
          <Input name="name" ref={register} />
        </SearchItem>

        <SearchItem>
          <p className="text">Trạng thái</p>
          <Controller
            control={control}
            name="status"
            defaultValue={{ ...status[0] }}
            render={({ onChange, value }) => {
              return (
                <Select options={status} onChange={onChange} value={value} />
              );
            }}
          />
        </SearchItem>

        <SearchItem>
          <p className="text">Danh mục</p>
          <Controller
            control={control}
            name="category"
            defaultValue={{ ...categoryOptions[0] }}
            render={({ onChange, value }) => {
              return (
                <Select
                  options={categoryOptions}
                  onChange={onChange}
                  value={value}
                />
              );
            }}
          />
        </SearchItem>
      </SearchWrapper>

      <ButtonWrapper>
        <Button type="submit">Tìm kiếm</Button>
      </ButtonWrapper>
    </SearchLayout>
  );
}
