/**
 *
 * Paginations
 * make by phamthainb
 */
import styled from 'styled-components';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import theme from '@styles/theme';
import P from 'rc-pagination';
import './styles.css';

const Pagination = styled(P)``;

export default Pagination;
