/*
 *
 * PaymentLinks selecters
 * make by phamthainb
 */
import rootReducer from 'src/redux/root-reducers';

export const selectPaymentLinksStore = (
  state: ReturnType<typeof rootReducer>,
) => state.PaymentLinks;
