/**
 *
 * NotFoundPage
 *
 */
 import React, { memo } from "react";
 import styled from "styled-components";
 
 interface Props {}
 
 // eslint-disable-next-line
 function NotFoundPage({}: Props) {
   return (
     <StylesNotFoundPage
       onClick={() => {
         window.location.replace("/");
       }}
     >
       <img src={require("@assets/images/404.gif")} alt="404" />
     </StylesNotFoundPage>
   );
 }
 const StylesNotFoundPage = styled.div`
   display: flex;
   align-items: center;
   justify-content: center;
   background: #f1f5f9;
   height: 100vh;
 `;
 
 export default memo(NotFoundPage);
 