import { TPagination } from '@configs/types';
/*
 *
 * Refund actions
 * make by phamthainb
 */
import * as types from './constants';
import { action } from 'typesafe-actions';
import { requestInterToken, requestToken } from '@api/axios';
import API_URL from '@api/url';
import { TSearch } from './types';
import { clearParams } from '@helpers/format';

type TRefundGetData = {
  pagination: TPagination;
  search: TSearch;
};

type TRefundSetData = {
  data: any;
  total: number;
};

export const getData = ({ pagination, search }: TRefundGetData) => {
  const {
    status,
    endDate,
    startDate,
    code,
    codeBill,
  } = search;
  return requestToken({
    method: 'GET',
    url: API_URL.REFUND.GET,
    params: {
      take: pagination.pageSize,
      page: pagination.pageNumber,
      status,
      endDate,
      startDate,
      code,
      codeBill,
    },
  });
};

export const setData = ({ data, total }: TRefundSetData) => {
  return action(types.GET, { ...data, total });
};

export const setPaging = ({pageNumber, pageSize}: TPagination) => {
  return action(types.PAGINATION, { pageNumber, pageSize });
};

export const handleSearch = (search : TSearch) => {
  return action(types.SEARCH, {...search})
}

export const handlePostRefund = (data: FormData) => {
  return requestInterToken({
    url: API_URL.REFUND.POST,
    data,
    method: 'POST'
  })
}

export const handleExport = (params: any) => {
  return requestInterToken({
    url: API_URL.REFUND.EXPORT,
    method: 'GET',
    params: clearParams({...params}),
    responseType: 'blob',
  })
}
