import { useEffect, useState } from 'react';

/**
 * @param target number: seconds time
 * @returns count: status of timer: count %2 is timer fasle
 * @returns setCount: trigger timer run
 * @returns timeLeft: timeLeft
 */

const useCountDown = (target: number) => {
  // initialize timeLeft with the seconds prop
  const [timeLeft, setTimeLeft] = useState(target);
  const [intervalId, setIntervalId] = useState<any>(null);
  const [count, setCount] = useState<number>(0); // %2 is fasle

  useEffect(() => {
    // save intervalId to clear the interval when the
    // component re-renders
    if (count % 2 !== 0 && timeLeft > 0) {
      const intervalId = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
      setIntervalId(intervalId);
    }

    // exit early when we reach 0
    if (!timeLeft) {
      setCount(c => c + 1);
      clearInterval(intervalId);
      setTimeLeft(target);
    }

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLeft, count]);

  return { timeLeft, count, setCount };
};
export default useCountDown;
