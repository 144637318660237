/*
 *
 * Dashboard
 * make by phamthainb
 */

import React from 'react';
import { StyleModal } from './style';
import { useState } from 'react';
import { useEffect } from 'react';
import { requestToken } from '@api/axios';
import API_URL from '@api/url';
import { HandleError } from '@components/HandleResponse';
import { useSelector } from 'react-redux';
import { selectAppStore } from '@containers/App/store/selecters';
import { Alert } from '@components/Alert';
import ICopyLink from '@assets/images/copy_link.svg';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Button, TabsContent as Tabs } from '@components/index';

import QRCode from './QRCode';

export default function LinkShop() {
  const { user } = useSelector(selectAppStore);
  const [linkUrl, setlinkUrl] = useState('');

  useEffect(() => {
    requestToken({ method: 'GET', url: API_URL.SHOP.GET(user?.id) })
      .then(res => {
        setlinkUrl(`${window.location.origin}/shop/${res.data.url}`);
      })
      .catch(HandleError);
  }, [user]);

  return (
    <StyleModal>
      <Tabs.TabsComponent
        control={['Link', 'QRCode']}
        content={[<Link linkUrl={linkUrl} />, <QRCode linkUrl={linkUrl} />]}
      />
    </StyleModal>
  );
}

const Link = ({ linkUrl }: { linkUrl: string }) => {
  return (
    <div className="content-tab">
      <p className="link">{linkUrl}</p>
      <div className="buttons">
        <div className="button_copy">
          <CopyToClipboard
            text={linkUrl}
            onCopy={() => Alert({ icon: 'success', name: 'Đã sao chép.' })}
          >
            <Button>
              <img src={ICopyLink} alt="icon" />
              Sao chép link
            </Button>
          </CopyToClipboard>
        </div>
      </div>
    </div>
  );
};
