export const publicURL = (path: string) =>
  process.env.REACT_APP_SERVER_URL + '/' + path;

export enum TypeOtp {
  UPDATEMOBILE = 'update-mobile',
  WITHDRAW = 'withdraw',
  REGISTER = 'register',
}
// clear object
export const clean = (obj: { [key: string]: any }) => {
  for (var propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === ''
    ) {
      delete obj[propName];
    }
  }
  return obj;
};

export const cleanObject = (data: { [key: string]: any }) => clean(data);

export const getValueObject = (path: string, context: any) => {
  let result = context;
  let pathSplit = path.split('.');

  try {
    for (var i = 0; i < pathSplit.length; i++) {
      result = result[pathSplit[i]];
    }
  } catch (error) {
    return null;
  }

  return result;
};

export const utf8_to_b64 = (str: string) => {
  return window.btoa(unescape(encodeURIComponent(str)));
};

export const b64_to_utf8 = (str: string) => {
  return decodeURIComponent(escape(window.atob(str)));
};
