const API_URL = {
  PAYMENT: {
    SHOPEE: { INIT: 'payments/shopee/init' },
    MOMO: { INIT: 'payments/momo/init' },
    '9PAY': { INIT: 'payments/9pay/init' },
    '9PAY_QRCODE': { INIT: 'payments/9pay_qr/init' },
    APPOTA: { INIT: 'payments/appota/init' },
    SMART_PAY: { INIT: 'payments/bytepay/init' },
    VISA: { INIT: 'payments/visa/init', CALLBACK: 'payments/visa/callback' },
    VINID: { INIT: 'payments/vin-id/init' },
    VNPAY: { INIT: 'payments/vnpay/init' },
    VIETTEL: { INIT: 'payments/viettel/init' },
    NAPAS: { INIT: 'payments/napas/init', CHECK: 'payments/napas/check' },
    CHECK_LINK: (ID: string) => `payment/${ID}`,
    GET_FEE: 'payment/info-fee',
    LIST_GATEWAY: (merchant_id: string) =>
      `payment/gateway/list-merchant/${merchant_id}`,
  },
  PAYMENTLINK: {
    GET: 'payment/link',
    SUPPORT: 'support',
    GET_ID: (id: string) => `payment/link/${id}`,
    EXPORT: 'payment/link/export-excel',
    CHANGE_STATUS: (id: string, action: 'lock' | 'active') =>
      `payment/link/${action}/${id}`,
  },
  VERIFY: {
    GET_INFO: 'merchant/detail-verify-account',
    PUT_INFO: 'merchant/verify-account',
    GET_ENTERPRISE: 'merchant/detail-verify-enterprise',
    GET_ENTERPRISE_TYPE: 'merchant/enterprise-type',
    PUT_ENTERPRISE: 'merchant/verify-enterprise',
  },
  REFUND: {
    GET: 'refund/merchant',
    GET_REASON: 'reason/merchant/list/refund',
    POST: 'refund',
    EXPORT: '/refund/merchant/export-excel',
  },
  PRODUCTS: {
    GET: 'product',
    GET_CATEGORY: 'product/category',
    POST: 'product',
    PUT: 'product',
    DISABLE: 'product/disable',
    DUPLICATE: 'product/duplicate',
    EXPORT: 'product/export',
  },
  CUSTOMER: {
    GET: 'customer',
    POST: 'customer',
    PUT: 'customer',
    EXPORT: 'customer/export',
  },
  MERCHANT: {
    LOGIN: 'merchant/login',
    REGISTER: 'merchant/register',
    CHANGE_PASSWORD: 'merchant/change-password',
    FORGOT_PASSWORD: 'merchant/forgot-password',
    CHANGE_FORGOT_PASSWORD: 'merchant/forgot-password/change-password',
    PROFILE: 'merchant/profile',
    UPDATE_ADDRESS: 'merchant/update-merchant-address',
    RESOURCE_ADDRESS: 'merchant/resource-address',
    UPDATE_MOBILE: 'merchant/update-mobile',
    CHECK_OTP: 'merchant/check-otp',
    CREATE_OTP: 'merchant/create-otp',
    UPDATE_BANK: 'merchant/update-bank',
  },
  MERCHANT_BANK: {
    BASE: 'merchant-bank',
    GET_LIST: 'merchant-bank/bank/list',
    PUT_CHANGE_INFORBANK: (ID: string) => `merchant-bank/${ID}`,
  },
  AUTH: {
    LOGIN: 'auth/login',
    CHANGE_PASSWORD: 'auth/change-password',
  },
  DASHBOARD: {
    STATISTIC: 'merchant/dashboard/statistic',
    TRANSACTION_CHART: 'transaction/merchant-chart',
    TRANSACTION_SUCCESS: 'merchant/dashboard/transaction-success-diagram',
    MERCHANT: 'merchant/dashboard/merchant-diagram',
    PAYMENT_CHART: 'payment/merchant-chart',
    LINK_STATISTIC: 'merchant/dashboard/payment-link-statistic', // RIGHT SIDEBAR
  },
  INFORUSER: {
    GETINFORCOMMON: 'merchant/profile',
    GETCOUNTRY: 'merchant/resource-address',
  },
  TRANSACTION: {
    GET: 'transaction/merchant',
    EXPORT: 'transaction/merchant/export-excel',
  },
  NOTIFICATION: {
    GETNOTIFICATION: 'notification/merchant/list',
    GETDETAILNOTIFI: 'notification/merchant/detail',
    GETCOUNTNOTIFI: 'notification/count',
  },
  WITHDRAW: {
    GET: 'withdraw/merchant',
    POST: 'withdraw',
    POSTOTP: 'merchant/create-otp',
    PUTCHECKOTP: 'merchant/check-otp',
    GETBANK: 'merchant-bank',
    GET_FEE: 'withdraw/caculate-fee',
  },
  SHOP: {
    GET: (merchantId: string) => `shop/find-by-merchant/${merchantId}`,
    GET_URL: (url: string) => `shop/${url}`,
    PUT: (ID: string) => `shop/${ID}`,
    POST: 'shop',
    CREATE_LINK: 'shop/create-payment-link',
    CREATE_LINK_SHOP: 'shop/create-link',
  },
  ORDER: {
    GET: 'payment/product/merchant',
    DETAILS: (paymentLink: string) =>
      `payment/product/merchant/detail?paymentLinkId=${paymentLink}`,
  },
  OTP: {
    SEND: 'merchant/create-otp-register',
    CHECK: 'merchant/check-otp-register',
  },
  KEYWORD: {
    BLACKLIST: '/keyword',
  },
  BUYER: {
    GET_OTP: '/buyer/get-otp',
    CHECK_OTP: '/buyer/verify-otp',
    PROFILE: '/buyer',
    ORDER: '/buyer/order',
    ORDER_ID: (id: string) => `/buyer/order/${id}`,
  },
};
export default API_URL;
