import React from 'react';
// import DemoComponent from '@components/DemoComponent';
import Dashboard from '@containers/Dashboard';
import TransactionList from '@containers/TransactionList';
import Withdrawal from '@containers/Withdrawal';
import Refund from '@containers/Refund';
import PaymentLinks from '@containers/PaymentLinks';
import AccountVerify from '@containers/AccountVerify';
import Products from '@containers/Products';
//import Customer from '@containers/Customer';
import Order from '@containers/Order';

// import icon, img
import IHome from '@assets/images/icon-home.svg';
import ITransections from '@assets/images/icon-transections.svg';
import IPayments from '@assets/images/icon-payments.svg';
import IProduct from '@assets/images/control_product.svg';
import ICustomer from '@assets/images/control_client.svg';
import IAccountVerify from '@assets/images/icon-account-verify.svg';

export interface ItemRoute {
  name: string;
  private: boolean;
  component?: Function;
  exact?: boolean;
  child?: ItemRoute[];
  path?: string;
  sidebar?: boolean; // show on sidebar nav
  icon?: any;
}

const ROUTES = {
  DASHBROAD: [
    {
      name: 'Dashboard',
      private: true,
      component: Dashboard,
      path: '/dashboard/index',
      exact: true,
      icon: () => <img src={IHome} alt="icon nav bar" />,
    },
    {
      name: 'Danh sách giao dịch',
      private: true,
      component: PaymentLinks,
      path: '/dashboard/payment-links',
      icon: () => <img src={IPayments} alt="icon nav bar" />,
    },
    {
      name: 'Quản lý giao dịch',
      private: true,
      // component: TransactionList,
      // path: '/dashboard/transaction-list',
      icon: () => <img src={ITransections} alt="icon nav bar" />,
      child: [
        {
          name: 'Giao dịch',
          private: true,
          component: TransactionList,
          path: '/dashboard/transaction-list',
        },
        {
          name: 'Rút tiền',
          private: true,
          component: Withdrawal,
          path: '/dashboard/withdrawal',
        },
        {
          name: 'Hoàn tiền',
          private: true,
          component: Refund,
          path: '/dashboard/refund',
        },
      ],
    },
    {
      name: 'Quản lý gian hàng',
      private: true,
      component: Products,
      path: '/dashboard/products',
      icon: () => <img src={IProduct} alt="icon nav bar" />,
    },
    // {
    //   name: 'Quản lý khách hàng',
    //   private: true,
    //   component: Customer,
    //   path: '/dashboard/customer',
    //   icon: () => <img src={ICustomer} alt="icon nav bar" />,
    // },
    {
      name: 'Quản lý đơn hàng',
      private: true,
      component: Order,
      path: '/dashboard/order',
      icon: () => <img src={ICustomer} alt="icon nav bar" />,
    },
    {
      name: 'Xác thực tài khoản',
      private: true,
      component: AccountVerify,
      path: '/dashboard/account-verify',
      icon: () => <img src={IAccountVerify} alt="icon nav bar" />,
    },
  ],
};

export default ROUTES;
