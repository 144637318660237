import { requestInterToken } from '@api/axios';
import API_URL from '@api/url';
import Button from '@components/Button';
import { HandleError, HandleSuccess } from '@components/HandleResponse';
import React from 'react';
import styled from 'styled-components';

type Props = {
  data: { [key in string]: any };
  toggle?: any;
  callback?: any;
};

export default function ModalChangeStatus({ data, toggle, callback }: Props) {
  const onsubmit = () => {
    requestInterToken({
      method: 'PUT',
      url: API_URL.PAYMENTLINK.CHANGE_STATUS(
        data.id,
        data.status === 'lock' ? 'active' : 'lock',
      ),
    })
      .then(res => {
        HandleSuccess(res);
        toggle();
        callback();
      })
      .catch(HandleError);
  };

  return (
    <SModalChangeStatus>
      <h2>Bạn chắc chắn muốn thay đổi trạng thái chứ ?</h2>
      <p>
        Trạng thái sẽ chuyển từ{' '}
        <span className="bold-text">
          {data.status === 'lock' ? 'Khóa' : 'Hoạt động '}
        </span>{' '}
        sang{' '}
        <span className="bold-text">
          {data.status === 'lock' ? 'Hoạt động ' : 'Khóa'}
        </span>
      </p>
      <div className="btn">
        <Button color="red" onClick={() => toggle()}>
          Hủy
        </Button>
        <Button onClick={() => onsubmit()}>Đồng ý</Button>
      </div>
    </SModalChangeStatus>
  );
}

const SModalChangeStatus = styled.div`
  .bold-text {
    font-weight: 600;
  }
  .btn {
    padding: 24px;
    display: flex;
    justify-content: space-evenly;

    gap: 12px;
    button {
      min-width: 100px;
    }
  }
`;
