/*
*
* Products constants
*  make by phamthainb
*/

export const GET = 'Products/GET';
export const PAGINATION = 'Products/PAGINATION';
export const DELETE = 'Products/DELETE';
export const ON_REFRESH = 'Products/REFRESH';
export const SEARCH = 'Products/SEARCH'

// messages
export const MSG_DISABLE_SUCCESS = 'Đã vô hiệu hóa sản phẩm';
export const MSG_DELETE_SUCCESS = 'Đã xóa sản phẩm';