/*
 *
 * StorePage reducers
 * make by phamthainb
 */
import { Reducer } from 'redux';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as types from './constants';
import { ActionsStorePage, StoreStorePage } from './types';

const initState: StoreStorePage = {
  shop_products: {
    data: [],
    pagination: {
      pageNumber: 1,
      pageSize: 12,
      total: 0,
    },
  },
  list_products: {
    search: {},
    data: [],
    pagination: {
      pageNumber: 1,
      pageSize: 12,
      total: 0,
    },
  },
  cartRefesh: false,
  current_product: undefined,
  customer: undefined,
  buyer: {},
};

const reducersStorePage: Reducer<StoreStorePage, ActionsStorePage> = (
  state = initState,
  actions: ActionsStorePage,
) => {
  switch (actions.type) {
    case types.SET_SEARCH: {
      return {
        ...state,
        list_products: {
          ...state.list_products,
          search: actions.payload,
          pagination: {
            ...state.list_products.pagination,
            pageNumber: 1,
          },
        },
      };
    }

    case types.GET_BUYER: {
      return {
        ...state,
        buyer: { ...actions.payload },
      };
    }

    case types.SET_LIST_PRODUCT: {
      const { page, total } = actions.payload.pagination;

      return {
        ...state,
        list_products: {
          ...state.list_products,
          data: actions.payload.data,
          pagination: {
            ...state.list_products.pagination,
            pageNumber: page,
            total: total,
          },
        },
      };
    }

    case types.SET_LIST_PRODUCT_PAGE: {
      return {
        ...state,
        list_products: {
          ...state.list_products,
          pagination: {
            ...state.list_products.pagination,
            pageNumber: actions.payload,
          },
        },
      };
    }

    case types.SET_SHOP_PRODUCT: {
      const { page, total } = actions.payload.pagination;

      return {
        ...state,
        shop_products: {
          data: actions.payload.data,
          pagination: {
            ...state.shop_products.pagination,
            pageNumber: page,
            total: total,
          },
        },
      };
    }
    case types.SET_SHOP_PAGE: {
      return {
        ...state,
        shop_products: {
          ...state.shop_products,
          pagination: {
            ...state.shop_products.pagination,
            pageNumber: actions.payload,
          },
        },
      };
    }

    case types.SET_PICKER: {
      return { ...state, current_product: actions.payload };
    }

    case types.CART_REFESH: {
      return { ...state, cartRefesh: !state.cartRefesh };
    }

    default:
      return { ...state };
  }
};

export default reducersStorePage;
