import { TGetData, TSearch, TSetData } from './types';
import { TPagination } from '@configs/types';
/*
 *
 * TransactionList actions
 * make by phamthainb
 */
import * as types from './constants';
import { action } from 'typesafe-actions';
import { requestInterToken, requestToken } from '@api/axios';
import API_URL from '@api/url';
import { clearParams } from '@helpers/format';

export const changeLogin = (val: boolean) => action(types.GET, val);

export const setPaging = ({ pageSize, pageNumber }: TPagination) => {
  return action(types.PAGINATION, { pageSize, pageNumber });
};

export const getData = ({ pagination, type, search }: TGetData) => {
  return requestToken({
    method: 'GET',
    url: API_URL.TRANSACTION.GET,
    params: clearParams({
      take: pagination.pageSize,
      page: pagination.pageNumber,
      ...search,
      type,
    }),
  });
};
export const getDataWithDraw = ({ pagination, search }: TGetData) => {
  return requestToken({
    method: 'GET',
    url: API_URL.WITHDRAW.GET,
    params: clearParams({
      take: pagination.pageSize,
      page: pagination.pageNumber,
      ...search,
    }),
  });
};

export const setData = ({ data, total }: TSetData) => {
  return action(types.GET, { ...data, total });
};

export const setType = (type: string) => {
  return action(types.CHANGE_TYPE, type);
};

export const handleSearch = (search: TSearch) => {
  return action(types.SEARCH, search);
};

export const handleExport = (params: any) => {
  return requestInterToken({
    method: 'GET',
    url: API_URL.TRANSACTION.EXPORT,
    params: clearParams({ ...params }),
    responseType: 'blob',
  });
};
