/*
 *
 * StorePage actions
 * make by phamthainb
 */
import * as types from './constants';
import { action } from 'typesafe-actions';
import { TCart, TProduct } from './types';

export const setSearch = (val: { key: string; categortId: string }) =>
  action(types.SET_SEARCH, val);

export const setListProducts = (val: { data: any; pagination: any }) => {
  return action(types.SET_LIST_PRODUCT, val);
};

export const setShopProduct = (data: { data: any; pagination: any }) =>
  action(types.SET_SHOP_PRODUCT, data);

export const setListProductsPage = (val: number) =>
  action(types.SET_LIST_PRODUCT_PAGE, val);

export const setShopProductPage = (val: number) =>
  action(types.SET_SHOP_PAGE, val);

export const setPicker = (val: TProduct) => action(types.SET_PICKER, val);

// Cart use localStorage
export const addProducttoCart = (
  val: TProduct,
  quantity: number,
  update?: boolean, // force update (no sum exist count)
) => {
  const getCart: TCart = JSON.parse(
    localStorage.getItem('data-cart-v1') || '{}',
  );
  let listCart: TCart['list'] = [];

  if (getCart?.list?.length > 0) {
    // has item on cart
    let isAdded = false;
    // exist
    listCart = getCart.list.map(item => {
      if (item.item.id === val.id) {
        isAdded = true;
        return { ...item, count: update ? quantity : item.count + quantity };
      } else return item;
    });
    // no exist
    if (!isAdded) {
      listCart = [
        ...getCart.list,
        {
          item: val,
          count: quantity,
          isCheck: false,
        },
      ];
    }
  } else {
    // newbie
    listCart.push({ item: val, isCheck: true, count: quantity });
  }

  let total = caculatorTotal(listCart);

  localStorage.setItem(
    'data-cart-v1',
    JSON.stringify({ list: listCart, total }),
  );

  return action(types.CART_REFESH);
};

export const delonCart = (id: string) => {
  const getCart: TCart = JSON.parse(
    localStorage.getItem('data-cart-v1') || '{}',
  );
  let listCart: TCart['list'] = [];

  if (getCart?.list?.length > 0) {
    listCart = getCart.list.filter(data => data.item.id !== id);
  }

  let total = caculatorTotal(listCart);

  localStorage.setItem(
    'data-cart-v1',
    JSON.stringify({ list: listCart, total }),
  );

  return action(types.CART_REFESH);
};

export const delPickeronCart = () => {
  const getCart: TCart = JSON.parse(
    localStorage.getItem('data-cart-v1') || '{}',
  );
  let listCart: TCart['list'] = [];

  if (getCart?.list?.length > 0) {
    listCart = getCart.list.filter(data => !data.isCheck);
  }

  let total = caculatorTotal(listCart);

  localStorage.setItem(
    'data-cart-v1',
    JSON.stringify({ list: listCart, total }),
  );

  return action(types.CART_REFESH);
};

export const setCheckonCart = (id: string, val: boolean) => {
  const getCart: TCart = JSON.parse(
    localStorage.getItem('data-cart-v1') || '{}',
  );
  let listCart: TCart['list'] = [];

  if (getCart?.list?.length > 0) {
    listCart = getCart.list.map(data => {
      if (data.item.id === id) {
        return { ...data, isCheck: val };
      }
      return data;
    });
  }

  let total = 0;
  listCart.forEach(data => {
    if (data.isCheck) {
      total +=
        (data.item.price / 100) * (100 - data.item.discount) * data.count;
    }
  });

  localStorage.setItem(
    'data-cart-v1',
    JSON.stringify({ list: listCart, total }),
  );

  return action(types.CART_REFESH);
};

export const caculatorTotal = (listCart: TCart['list']) => {
  let total = 0;
  listCart.forEach(data => {
    if (data.isCheck) {
      total +=
        (Number(data.item.price) / 100) *
        (100 - Number(data.item.discount ?? 0)) *
        data.count;
    }
  });

  return total;
};

/// update
// shop {merchant: any, product: {item: any, isCheck: boolean, count: number}[], is_pick_able: boolean}[]
export const add_to_cart = (data: {
  product: any;
  quantity: number;
  merchant: any;
  add_more?: boolean;
}) => {
  const { product, quantity, merchant, add_more } = data;
  let get_cart_data = localStorage.getItem(types.NAME_STORE_LOCAL);
  let cart_data = JSON.parse(get_cart_data || '[]') || [];
  let clone_cart = [...cart_data];
  let exist_prod = false,
    exist_shop = false;

  clone_cart.forEach((shop, index) => {
    if (shop.merchant.id === merchant.id) {
      // if shop exist
      exist_shop = true;
      clone_cart[index].product.forEach((prod: any, jndex: number) => {
        if (prod.item.id === product.id) {
          // if prod exist
          let current_prod = { ...cart_data[index].product[jndex] };
          cart_data[index].product[jndex] = {
            ...current_prod,
            count: add_more ? current_prod.count + quantity : quantity,
          };
          exist_prod = true;
        }
      });
      // if shop exist && prod not exist
      if (!exist_prod) {
        cart_data[index].product = [
          ...clone_cart[index].product,
          { count: quantity, isCheck: false, item: product },
        ];
      }
    }
  });
  if (!exist_shop) {
    // if shop not exist
    cart_data.push({
      is_pick_able: true,
      merchant: merchant,
      product: [
        {
          item: product,
          isCheck: false,
          count: quantity,
        },
      ],
    });
  }
  localStorage.setItem(types.NAME_STORE_LOCAL, JSON.stringify(cart_data));
  return action(types.CART_REFESH);
};

export const del_to_cart = (proId: string) => {
  let get_cart_data = localStorage.getItem(types.NAME_STORE_LOCAL);
  let cart_data = JSON.parse(get_cart_data || '[]');
  let clone_cart = [...cart_data];

  clone_cart.forEach((shop, index) => {
    clone_cart[index].product.forEach((prod: any, jndex: number) => {
      if (prod.item.id === proId) {
        // console.log('cart_data[index].product', cart_data[index].product);
        if (cart_data[index].product.length > 1) {
          cart_data[index].product = cart_data[index].product.filter(
            (k: any) => k.item.id !== proId,
          );
        } else {
          cart_data = cart_data.filter((k: any, i: number) => i !== index);
        }
      }
    });
  });

  localStorage.setItem(types.NAME_STORE_LOCAL, JSON.stringify(cart_data));
  return action(types.CART_REFESH);
};

export const del_shop_to_cart = (shop_index: number) => {
  let get_cart_data = localStorage.getItem(types.NAME_STORE_LOCAL);
  let cart_data = JSON.parse(get_cart_data || '[]');
  let clone_cart = [...cart_data];

  cart_data = clone_cart
    .filter((k, i) => i !== shop_index)
    .map(k => ({ ...k, is_pick_able: true }));

  localStorage.setItem(types.NAME_STORE_LOCAL, JSON.stringify(cart_data));
  return action(types.CART_REFESH);
};

export const set_shop_pickable_on_cart = (index_shop: number) => {
  let get_cart_data = localStorage.getItem(types.NAME_STORE_LOCAL);
  let cart_data = JSON.parse(get_cart_data || '[]');
  let clone_cart = [...cart_data];

  clone_cart.forEach((shop, index) => {
    if (index === index_shop) {
      cart_data[index] = { ...clone_cart[index], is_pick_able: true };
    } else {
      cart_data[index] = { ...clone_cart[index], is_pick_able: false };
    }
  });

  localStorage.setItem(types.NAME_STORE_LOCAL, JSON.stringify(cart_data));
  return action(types.CART_REFESH);
};

export const toggle_pick_on_cart = (proId: string) => {
  let get_cart_data = localStorage.getItem(types.NAME_STORE_LOCAL);
  let cart_data = JSON.parse(get_cart_data || '[]');
  let clone_cart = [...cart_data];
  let shop_index: any = null,
    is_last_pick = true;

  clone_cart.forEach((shop, index) => {
    clone_cart[index].product.forEach((prod: any, jndex: number) => {
      if (prod.item.id === proId) {
        let pick = cart_data[index].product[jndex].isCheck;
        cart_data[index].product[jndex] = {
          ...cart_data[index].product[jndex],
          isCheck: !pick,
        };
        // set index pick able of shop
        shop_index = index;
      }
    });
  });
  // check is last pick
  cart_data[shop_index].product.forEach((k: any) => {
    if (k.isCheck) {
      is_last_pick = false;
    }
  });
  clone_cart.forEach((shop, index) => {
    if (shop_index !== index) {
      cart_data[index] = {
        ...cart_data[index],
        is_pick_able: false,
        product: cart_data[index].product.map((k: any) => ({
          ...k,
          isCheck: false,
        })),
      };
    }
    if (is_last_pick) {
      cart_data[index] = {
        ...cart_data[index],
        is_pick_able: true,
        product: cart_data[index].product.map((k: any) => ({
          ...k,
          isCheck: false,
        })),
      };
    }
  });

  localStorage.setItem(types.NAME_STORE_LOCAL, JSON.stringify(cart_data));
  return action(types.CART_REFESH);
};

export const update_to_cart = (data: any) => {
  localStorage.setItem(types.NAME_STORE_LOCAL, JSON.stringify(data));
  return action(types.CART_REFESH);
};

export const setBuyer = (val: any) => {
  return action(types.GET_BUYER, val);
};
