/*
 *
 * Withdrawal actions
 * make by phamthainb
 */
import * as types from './constants';
import { action } from 'typesafe-actions';
import { requestToken } from '@api/axios';
import API_URL from '@api/url';

export const changeLogin = (val: boolean) => action(types.GET, val);

export const setData = (val: any) => action(types.SETDATA, val);

export const getBanks = () => {
  return requestToken({
    method: 'GET',
    url: API_URL.WITHDRAW.GETBANK,
    params: {
      take: 10,
      page: 1,
    },
  });
};

export const setBanks = (data: any, total: number) => {
  return action(types.SETBANK, { data: data.map((item: any) => ({ ...item, label: item.bank.name, value: item.id })), total });
};

export const setTransactionId = (val: any) => action(types.SETTRANSACTIONTD, val)