import React, { useEffect, useRef, useState } from 'react';
import Dots from '@assets/images/vertical-dots.svg';
import styled from 'styled-components';
import theme from '@styles/theme';

interface option {
  callback: (e: any) => void;
  name: JSX.Element | string;
}

interface TMoreOptionsProps {
  options: option[];
  style: TOptionsProps;
  dotSize?: number;
}

interface TOptionsProps {
  position: string;
}

export default function MoreOptions({
  options,
  style,
  dotSize = 16,
}: TMoreOptionsProps) {
  const dotRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (dotRef.current && !dotRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <SMoreOptions ref={dotRef}>
      <img
        src={Dots}
        alt="close options"
        style={{ width: `${dotSize}px`, height: `${dotSize}px` }}
        className="dots"
        onClick={() => setIsOpen(prev => !prev)}
      />
      <SOptions
        {...style}
        style={{
          display: isOpen ? 'block' : 'none',
        }}
      >
        {options.map((option: option, index: number) => {
          return (
            <div
              className="option"
              key={'option' + index}
              onClick={e => {
                option.callback(e);
                setIsOpen(false);
              }}
            >
              {option.name}
            </div>
          );
        })}
      </SOptions>
    </SMoreOptions>
  );
}

const SMoreOptions = styled.div`
  position: relative;
  margin-left: auto;
  .dots {
    cursor: pointer;
  }
`;

const SOptions = styled.div<TOptionsProps>`
  padding: 8px 0px;
  width: auto;
  white-space: nowrap;
  position: absolute;
  right: 0;
  z-index: 1;
  bottom: ${props =>
    props.position === 'bottom' ? 'calc(100% + 10px)' : null};
  top: ${props => {
    if (props.position === 'top') return 'calc(100% + 10px)';
    else if (props.position === 'center') return '50%';
    return null;
  }};
  background-color: white;
  box-shadow: 5px 10px 20px rgba(113, 119, 150, 0.1);
  animation: ${props =>
      props.position === 'center' ? `centerTransform` : `normalTransform`}
    0.15s ease-in-out forwards;
  transform-origin: ${props => props.position};
  border: 1px solid #e3e8f0;
  border-radius: 4px;
  .option {
    padding: 0 12px;
    text-align: right;
    cursor: pointer;
    margin: 0;
    font-size: 14px;
    line-height: 24px;
    /* color: body */
      color: ${theme.color.text_chinh};
    &:hover {
      color: black;
      background-color: #e3e8f0;
    }
    &::before {
      content: '';
      display: block;
      position: absolute;
      right: 15px;
      bottom: 100%;
      width: 0;
      height: 0;
      border: 7px solid transparent;
      border-bottom-color: #e3e8f0;
    }
    &::after {
      content: '';
      display: block;
      position: absolute;
      right: 16px;
      bottom: 100%;
      width: 0;
      height: 0;
      border: 6px solid transparent;
      border-bottom-color: white;
    }
  }
  @keyframes centerTransform {
    from {
      transform: translateY(-50%) scaleY(0);
    }
    to {
      transform: translateY(-50%) scaleY(1);
    }
  }
  @keyframes normalTransform {
    from {
      transform: scaleY(0);
    }
    to {
      transform: scaleY(1);
    }
  }
`;
