import { TProductsGetData, TProductsSetData } from './types';
/*
 *
 * Products actions
 * make by phamthainb
 */
import * as types from './constants';
import { action } from 'typesafe-actions';
import { requestInterToken, requestToken } from '@api/axios';
import API_URL from '@api/url';
import { TPagination } from '@configs/types';
import { clearParams } from '@helpers/format';
import { HandleError } from '@components/HandleResponse';
import exportExcel from '@helpers/exportExcel';
export const changeLogin = (val: boolean) => action(types.GET, val);

export const setPaging = ({ pageNumber, pageSize }: TPagination) => {
  return action(types.PAGINATION, { pageNumber, pageSize });
};

export const getData = ({ pagination, search }: TProductsGetData) => {
  return requestToken({
    method: 'GET',
    url: API_URL.PRODUCTS.GET,
    params: clearParams({
      take: pagination.pageSize,
      page: pagination.pageNumber,
      keyword: search.keyword,
      category_id: search.categoryId,
      status: search.status,
      adminApprove: search.adminApprove,
      categoryStatus: search.categoryStatus,
      isDisable: search.isDisable,
    }),
  });
};

export const setData = ({ data, total }: TProductsSetData) => {
  return action(types.GET, { ...data, total });
};

export const onRefresh = () => {
  return action(types.ON_REFRESH);
};

export const handleDisableProduct = (product: any) => {
  return requestInterToken({
    method: 'PUT',
    url: `${API_URL.PRODUCTS.DISABLE}/${product.id}`,
  });
};

export const handleDeleteProduct = (product: any) => {
  return requestInterToken({
    method: 'DELETE',
    url: `${API_URL.PRODUCTS.PUT}/${product.id}`,
  });
};

export const handlePostProduct = (data: FormData) => {
  return requestInterToken({
    url: API_URL.PRODUCTS.POST,
    data,
    method: 'POST',
  });
};

export const handleUpdateProduct = (data: FormData, id: string) => {
  return requestInterToken({
    url: `${API_URL.PRODUCTS.POST}/${id}`,
    data: data,
    method: 'PUT',
  });
};

export const handleGetOneProduct = (id: string) => {
  return requestInterToken({
    method: 'GET',
    url: `${API_URL.PRODUCTS.GET}/${id}`,
  });
};

export const handleSearch = (search: any) => {
  return action(types.SEARCH, search);
};

export const handleDuplicate = (id: string) => {
  return requestInterToken({
    url: `${API_URL.PRODUCTS.DUPLICATE}/${id}`,
    method: 'GET',
  });
};

export const handleExport = (params: any) => {
  return requestInterToken({
    url: API_URL.PRODUCTS.GET,
    method: 'GET',
    params: clearParams({ ...params, listType: 'excel' }),
  })
    .then(res => {
      const rs = res.data;
      const dataExcel = rs.map((product: any, index: number) => ({
        STT: index + 1,
        'Tên sản phẩm': product.name,
        Giá: product.price,
        'Tổng bán': product.total,
        'Doanh thu': (
          parseInt(product.total) * parseInt(product.price)
        ).toString(),
        'Tình trạng':
          product?.status === 'active' ? 'Hoạt động' : 'Không hoạt động',
      }));

      exportExcel(dataExcel, 'Danh sách sản phẩm');
    })
    .catch(HandleError);
};
